import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.slider_temoignages').slick({
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 2,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					arrows: true,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
		{
			breakpoint: 600,
			settings: {
				arrows: false,
				slidesToShow: 1,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 480,
				settings: {
					arrows: false,
					dots: false,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});	


});